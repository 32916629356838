import { useState } from 'react';
import { TextField, Typography, CardContent, Box, Link, Container, Card, CardHeader } from "@mui/material";
import Button from 'lib/component/ATForm/UI/Button/Button';
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Snackbar
import { useSnackbar } from 'notistack'
// import { ColorPalette } from 'constants/ColorPalette/ColorPalette';

const styles = {
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        marginTop: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        // background: ColorPalette.mechaButtonBlue,
        height: '54px',
        textAlign: 'center',        
        color: 'black',
        borderRadius: '50px'
    }
}

const Copyright = () => {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="">
                MechaSource
            </Link>{' '}
            {new Date().getFullYear()}
            {'  '}
            {'v'}
            {` ${process.env.REACT_APP_VERSION}`}
        </Typography>
    );
}

const Login = ({ onLoginSuccess }) => {
    const { enqueueSnackbar } = useSnackbar()

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    const onSubmitClick = () => {
        ServiceManager.login({ username, password })
            .then(res => {
                onLoginSuccess(res)
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    enqueueSnackbar('Incorrect username or password!', { variant: 'error' })
                }
            })
    }

    return <Container component="main" maxWidth="xs" style={{ background: 'solid ' }}>
        <Card sx={styles.card}>
            <CardHeader sx={styles.header}
                title={`Mecha Dashboard`}
            />
            <CardContent>
                <div style={styles.paper}>                    
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={'Email'}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(event) => setUsername(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSubmitClick()
                            }
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                onSubmitClick()
                            }
                        }}
                    />
                    <Button variant={'contained'} fullWidth={true} sx={{ marginTop: '15px' }} onClick={onSubmitClick} label={'Login'} color={'secondary'} />
                </div>
                <Box mt={8}>
                    <Copyright />
                </Box>
            </CardContent>
        </Card>
    </Container>
}

export default Login;