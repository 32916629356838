import { useCallback, useRef } from "react";

import { ATFormDialog, formBuilder } from "lib"

import { ActiveQuestionsRecordColumns } from "constants/Columns/Columns";

const AnsweredQuestionsRecordDialog = ({ defaultValue, data, onClose }) => {
    const formRef = useRef(null)

    const refCallback = useCallback((ref) => {
        if (ref) {
            ref.reset(defaultValue)
            formRef.current = ref
        }
    }, [defaultValue])


    return <ATFormDialog ref={refCallback} onClose={onClose}>
        {
            [
                formBuilder.createLabel({ id: 'Summary', label: data.Summary, md: 12, sx: { fontWeight: 'bold' } }),
                formBuilder.createLabel({ id: 'Question', label: data.Body, md: 12 }),
                formBuilder.createTextBox({ id: 'AnswerHeadline', label: 'Headline', md: 12, InputProps: { readOnly: true } }),
                formBuilder.createTextBox({ id: 'AnswerBody', label: 'Answer', ...ActiveQuestionsRecordColumns.find(item => item.id === 'Body').uiProps, md: 12, InputProps: { readOnly: true } })
            ]
        }
    </ATFormDialog>
}

export default AnsweredQuestionsRecordDialog;