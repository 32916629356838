const localText = {
    'Add': 'Add',
    'Passwords do not match': 'Passwords do not match',
    'Confirm': 'Confirm',
    'file': 'file',
    'Uploading': 'Uploading',
    'Add Files': 'Add Files',
    'files': 'files',
    'Delete All': 'Delete All',
    'Yes': 'Yes',
    'No': 'No',
    'Cancel': 'Cancel',
    'Submit': 'Submit',
    'Edit': 'Edit',
    'Remove': 'Remove',
}

export default localText