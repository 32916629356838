//assets
import { Button } from '@mui/material';
import MechaLogo from 'assets/MechaLogo.png';

import StyleClasses from './Logo.module.css';

const Logo = () => {
    return <Button sx={{display: 'content'}}>
        <div className={StyleClasses.Logo}>
            <img className={StyleClasses.LogoImage} src={MechaLogo} alt={"Mecha Dashboard"} />
        </div>
    </Button>
}

export default Logo