
import { QuestionsColumns } from 'constants/Columns/Columns';
//CellRenderers
import Button from 'components/AgGrid/CellRenderers/Button/Button';

export const getSkippedQuestionsColumnDefs = ({ onUnskipClick }) => {

    return [
        ...QuestionsColumns.filter(item => item.id !== 'RTime').map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),
            }
        }),
        {
            cellRenderer: Button,
            cellRendererParams: {
                onClick: onUnskipClick,
                color: 'mechaYellow'
            },
            field: 'Unskip',
            headerName: 'Unskip',
            width: 140,
            // pinned: 'right',
        },
    ]
}