export const ColorPalette = {
    primary500: '#1976d2',
    secondary500: '#9c27b0  ',
    white: 'white',
    remove: '#FD344E',
    mechaEditGrey: '#717171',
    mechaButtonBlue: '#3070F9',
    mechaButtonYellow: '#F5B02A',
    mechaButtonGreen: '#61D27B',
    mechaButtonGrey: '#F2F2F2',
    paletteColor_mechaButtonYellow: {
        light: '#ebc476',
        main: '#F5B02A',
        dark: '#af7d19',
        contrastText: 'white',
    },
    paletteColor_mechaButtonGrey: {
        light: '#ffffff',
        main: '#F2F2F2',
        dark: '#979797',
        contrastText: 'black',
    }
}