import { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
//MUI
import Container from '@mui/material/Container';
//AppHeader
import AppHeader from './AppHeader/AppHeader';
//Services
import ServiceManager, { clearLocalStorage, updateLocalStorage } from 'ServiceManager/ServiceManager';
//Redux
import { useSelector } from 'react-redux';
import { setUser } from 'app/slices/authenticationSlice/authenticationSlice';
import store from 'app/store';
//Pages
import LoginPage from './Pages/LoginPage/LoginPage';
import HomePage from './Pages/HomePage/HomePage';
import UserManagerPage from './Pages/UserManagerPage/UserManagerPage';
import SourceProfileManagerPage from './Pages/SourceProfileManager/SourceProfileManager';
import ActiveQuestionsPage from './Pages/ActiveQuestionsPage/ActiveQuestionsPage';
import AnsweredQuestionsPage from './Pages/AnsweredQuestionsPage/AnsweredQuestionsPage';
import MyAccountPage from './Pages/MyAccountPage/MyAccountPage';
import SkippedQuestionsPage from './Pages/SkippedQuestionsPage/SkippedQuestionsPage';
import RedirectHeadshotUrlPage from './Pages/RedirectHeadshotURLPage/RedirectHeadshotURLPage';

const MasterPage = () => {
    const isAuthenticated = useSelector((state) => state.authentication.isAuthenticated)
    const user = useSelector((state) => state.authentication.user)

    useEffect(() => {
        if (isAuthenticated)
            syncOpenAIConfig()
    }, [isAuthenticated])

    const syncOpenAIConfig = () => {
        ServiceManager.GetUserConfigs()
            .then(res => {
                localStorage.setItem('openAIConfig', JSON.stringify(res))
            })
    }

    const onLoginSuccess = (response) => {
        const { user } = updateLocalStorage(response)

        syncOpenAIConfig()
        store.dispatch(setUser(user))
    }

    const onLogoutClick = () => {
        clearLocalStorage()
        store.dispatch(setUser(null))
    }

    const pages = [
        {
            id: '',
            label: 'Home',
        },
        {
            id: 'ActiveQuestions',
            label: 'Active Questions',
        },
        {
            id: 'AnsweredQuestions',
            label: 'Answered Questions',
        },
        {
            id: 'SkippedQuestions',
            label: 'Skipped Questions',
        },
        {
            id: 'UserManager',
            label: 'User Manager',
            visible: user?.IsAdmin,
        },
        {
            id: 'SourceProfileManager',
            label: 'Source Profiles',
            visible: user?.IsAdmin,
        },
    ]

    return (
        <div>
            {isAuthenticated && <AppHeader pages={pages} user={user} onLogoutClick={onLogoutClick} />}
            <Container maxWidth={false} style={{ marginBottom: '0px', marginTop: '0px' }}>
                {
                    isAuthenticated ?
                        <Routes>
                            {user.IsAdmin && <Route path='UserManager' element={<UserManagerPage />} />}
                            <Route path='MyAccount' element={<MyAccountPage user={user} />} />
                            <Route path='SourceProfileManager' element={<SourceProfileManagerPage />} />
                            <Route path='ActiveQuestions' element={<ActiveQuestionsPage />} />
                            <Route path='AnsweredQuestions' element={<AnsweredQuestionsPage />} />
                            <Route path='SkippedQuestions' element={<SkippedQuestionsPage />} />
                            <Route path="/GetHeadshot/:id" element={<RedirectHeadshotUrlPage />} />
                            <Route path='*' element={<HomePage />} />
                        </Routes>
                        :
                        <Routes>
                            <Route path="/GetHeadshot/:id" element={<RedirectHeadshotUrlPage />} />
                            <Route path='*' element={<LoginPage onLoginSuccess={onLoginSuccess} />} />
                        </Routes>
                }
            </Container>
        </div>
    )
}

export default MasterPage;