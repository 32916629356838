
import { dateValueFormatter, QuestionsColumns } from 'constants/Columns/Columns';
//CellRenderers
import Button from 'components/AgGrid/CellRenderers/Button/Button';

export const getAnsweredQuestionsColumnDefs = ({ answerButtonDisabled, onShowAnswerClick }) => {

    return [
        {
            field: 'AnswerTime',
            headerName: 'Submit Date',
            valueFormatter: dateValueFormatter,
        },
        ...QuestionsColumns.filter(item => item.id !== 'RTime').map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),
            }
        }),
        {
            cellRenderer: Button,
            cellRendererParams: {
                onClick: onShowAnswerClick,
                disabled: answerButtonDisabled,
            },
            field: 'ShowAnswer',
            headerName: 'Show Answer',
            width: 140,
            // pinned: 'right',
        },
    ]
}