import { createSlice } from '@reduxjs/toolkit';

const initUser = () => {
  let result = null
    
  const user = localStorage.getItem('user')

  if (user && user !== '') {
    result = {
      ...JSON.parse(user),
    }
  }

  return result
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    isAuthenticated: initUser() !== null,
    user: initUser(),
  },
  reducers: {
    setUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes      
      state.user = action.payload;
      state.isAuthenticated = action.payload !== null
    },
  },
});

export const { setUser } = authenticationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.authentication.user;
export const selectIsAuthenticated = state => state.authentication.isAuthenticated;

export const authenticationReducer = authenticationSlice.reducer;
