import React, { useCallback, useRef } from 'react';
//MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
//Context
import Button from 'lib/component/ATForm/UI/Button/Button';
//Components
import UserSourceProfile from './UserSourceProfile/UserSourceProfile';

const UserSourceProfileDialog = ({ onClose, onSaveClick, data, defaultValue }) => {
    const gridRef = useRef(null)

    const gridRefCallBack = useCallback((ref) => {
        if (ref) {
            gridRef.current = ref
        }
    }, [])

    const onInternalSaveButtonClick = (event, { ...restProps }) => {
        const selectedRows = gridRef.current.api.getSelectedRows()

        onSaveClick(event, { ...restProps, selectedRows })
    }

    return <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth={'800'}>
        <DialogTitle >{'Edit Selected User Source Profile'}</DialogTitle>
        <DialogContent>
            <UserSourceProfile ref={gridRefCallBack} defaultValue={defaultValue} {...data} />
        </DialogContent>
        <DialogActions>
            <Grid container spacing={2} sx={{ justifyContent: 'end' }}>
                <Grid item md={2}>
                    <Button variant={'contained'} color={'secondary'} onClick={onInternalSaveButtonClick}>Save</Button>
                </Grid>
                <Grid item md={2}>
                    <Button variant={'contained'} onClick={onClose} >Cancel</Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
}

export default UserSourceProfileDialog;