//utils
import Avatar from 'components/AgGrid/CellRenderers/Avatar/Avatar';
import { formBuilder, UITypes } from 'lib';
import moment from 'moment';
import * as utils from 'utils/utils';

export const dateValueFormatter = (params, format = 'YYYY/MM/DD h:mm A') => {
    return params.value ? moment(params.value).format(format) : params.value
}

const Columns = [
    {
        id: 'Address',
        tags: ['sourceprofilemanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 160,
        }
    },
    {
        id: 'Company',
        tags: ['sourceprofilemanager'],
        gridProps: {
            width: 155
        },
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'Description',
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'CompanyDescription',
        tags: ['sourceprofilemanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 195
        }
    },
    {
        id: 'DisplayName',
        tags: ['sourceprofilemanager'],
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'Email',
        tags: ['usermanager', 'sourceprofilemanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 230
        }
    },
    {
        id: 'Headshot',
        tags: ['usermanager', 'sourceprofilemanager'],
        uiProps: {
            type: UITypes.Avatar,
            md: 12,
            size: 128,
            width: 512,
            height: 512,
        },
        gridProps: {
            cellRenderer: Avatar,
            width: 110,
            filter: false,
        }
    },
    {
        id: 'IsAdmin',
        label: 'Admin',
        tags: ['usermanager'],
        uiProps: {
            type: UITypes.CheckBox,
        },
        gridProps: {
            width: 100,
            valueFormatter: (params) => params.value ? 'Yes' : 'No'
        }
    },
    {
        id: 'LinkedInURL',
        label: 'Linked In URL',
        tags: ['sourceprofilemanager'],
        gridProps: {
            width: 140
        },
        uiProps: {
            type: UITypes.TextBox
        }
    },
    {
        id: 'Name',
        tags: ['sourceprofilemanager'],
        gridProps: {
            width: 160
        },
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'Title',
        tags: ['sourceprofilemanager'],
        gridProps: {
            width: 200
        },
        uiProps: {
            type: UITypes.TextBox
        }
    },
    {
        id: 'Username',
        tags: ['usermanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 140
        }
    },
    {
        id: 'WebsiteURL',
        label: 'Website URL',
        tags: ['sourceprofilemanager'],
        gridProps: {
            width: 140
        },
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'FirstName',
        tags: ['usermanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 140
        }
    },
    {
        id: 'LastName',
        tags: ['usermanager'],
        uiProps: {
            type: UITypes.TextBox,
        },
        gridProps: {
            width: 140
        }
    },
    {
        id: 'Password',
        tags: ['usermanager'],
        uiProps: {
            type: UITypes.DoublePasswordTextBox,
            md: 6,
            autoComplete: 'new-password',
            form: {
                autocomplete: 'off',
            },
        }
    },
    {
        id: 'SourceProfileID',
    },
    {
        id: 'Source',
        tags: ['questions'],
        gridProps: {
            wrapText: true,
            autoHeight: true,
            width: 180,
        }
    },
    {
        id: 'Summary',
        label: 'Question',
        tags: ['questions'],
        gridProps: {
            // wrapText: true,
            // autoHeight: true,
            flex: 1,
            suppressSizeToFit: true,
            resizable: false,
        }
    },
    {
        id: 'Outlet',
        tags: ['questions'],
        gridProps: {
            width: 150,
            wrapText: true,
        }
    },
    {
        id: 'DR',
        label: 'DR',
        gridProps: {
            width: 85,
        }
    },
    {
        id: 'Deadline',
        tags: ['questions'],
        gridProps: {
            width: 175,
            wrapText: true,
            autoHeight: true,
            valueFormatter: dateValueFormatter,
        }
    },
    {
        id: 'Body',
        tags: ['activequestionsrecorddialog'],
        uiProps: {
            type: UITypes.TextBox,
            multiline: true,
            rows: 10
        }
    },
    {
        id: 'Headline',
        tags: ['activequestionsrecorddialog'],
        uiProps: {
            type: UITypes.TextBox,
        }
    },
    {
        id: 'RTime',
        label: 'Date',
        tags: ['questions'],
        gridProps: {
            width: 118,
            wrapText: true,
            autoHeight: true,
            valueFormatter: (params) => dateValueFormatter(params, 'YYYY/MM/DD'),
        }
    },
    {
        id: 'IsActive',
        label: 'Is Active',
        uiProps: {
            type: UITypes.CheckBox,   
            defaultValue: true,         
        },
        gridProps: {
            valueFormatter: (params) => params.value ? 'Yes' : 'No',
            width: 110,
        },
        tags: ['sourceprofilemanager']
    }
]

export const RenderableColumns = Columns.map(item => ({ ...item, label: item.label || formBuilder.splitCapitalBySpace(item.id) }))
export const UserManagerColumns = RenderableColumns.filter(item => item.tags && item.tags.includes('usermanager')).sort((a, b) => utils.sortByArray(a.id, b.id, ['Headshot', 'Username', 'Password', 'FirstName', 'LastName', 'Email', 'IsAdmin']))
export const SourceProfileManagerColumns = RenderableColumns.filter(item => item.tags && item.tags.includes('sourceprofilemanager')).sort((a, b) => utils.sortByArray(a.id, b.id, ['Headshot', 'Name', 'Title']))
export const QuestionsColumns = RenderableColumns.filter(item => item.tags && item.tags.includes('questions')).sort((a, b) => utils.sortByArray(a.id, b.id, ['RTime', 'Source', 'Outlet', 'Deadline', 'Summary']))
export const ActiveQuestionsRecordColumns = RenderableColumns.filter(item => item.tags && item.tags.includes('activequestionsrecorddialog')).sort((a, b) => utils.sortByArray(a.id, b.id, ['Headline', 'Body']))