import React from 'react';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import './AgGrid.css';
import { Button } from '@mui/material';

const AgGrid = React.forwardRef(({ rowData, columnDefs, height, showTotalRow, rowSelection, isRowSelectable, onGridReady, rowMultiSelectWithClick }, ref) => {
    return <div className="ag-theme-alpine" style={{ height: height || '80vh', width: '100%' }}>
        <AgGridReact
            ref={ref}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={{
                sortable: true,
                filter: true,
            }}
            rowHeight={48}
            rowSelection={rowSelection}            
            isRowSelectable={isRowSelectable}
            onGridReady={onGridReady}
            rowMultiSelectWithClick={rowMultiSelectWithClick}
        />
        {
            showTotalRow
            &&
            <Button variant={'text'} sx={{ color: 'black' }}>
                Total Row: {rowData ? rowData.length : 0}
            </Button>
        }
    </div>
})

export default AgGrid;