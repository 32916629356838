import AgGrid from "components/AgGrid/AgGrid";
import React, { useEffect, useRef, useState } from "react";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//ColumnDefs
import { getUserSourceProfileColumnDefs } from "./UserSourceProfileColumnDefs/UserSourceProfileColumnDefs";

const UserSourceProfile = React.forwardRef(({ defaultValue }, forwardRef) => {
    const gridRef = useRef(null)
    const [rowData, setRowData] = useState(null)

    useEffect(() => {
        ServiceManager.getSourceProfiles()
            .then(res => {
                setRowData(res)
            })
    }, [])

    useEffect(() => {
        if (gridRef && gridRef.current && rowData) {
            gridRef.current.api.forEachNode(node => {
                const found = defaultValue.find(item => item.SourceProfileID === node.data.SourceProfileID)

                if (found)
                    node.setSelected(true);
            });
        }

        // eslint-disable-next-line
    }, [rowData])

    return <div>
        <AgGrid
            ref={gridRef}
            onGridReady={forwardRef}
            height={'65vh'}
            columnDefs={getUserSourceProfileColumnDefs()}
            rowData={rowData}
            rowSelection={'multiple'}
            isRowSelectable={false}
            rowMultiSelectWithClick={true}
        />
    </div>
})

export default UserSourceProfile;