import { useEffect, useState } from "react";

//MUI
import { CircularProgress, Grid } from "@mui/material";
//Components
import Chart from './Chart/Chart';
import ChartDialog from "./ChartDialog/ChartDialog";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';

const HomePage = () => {
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        ServiceManager.getCharts()
        .then(res => {
            setData(res)
        })
        .finally(() => {
            setLoading(false)
        })
    }, [])

    const onFullscreenViewClick = (id, d) => {
        const newDialog = <ChartDialog
            key={id}
            data={d}
            onClose={() => setDialog(null)}
        />

        setDialog(newDialog)
    }

    return <div style={{ marginBottom: '20px' }}>
        {
            loading ?
                <Grid container item xs={12} justifyContent={'center'}>
                    <CircularProgress />
                </Grid>
                :
                < Grid container spacing={2} alignItems={'center'} direction={'row'} >
                    {data.map(d => {
                        return <Chart key={d[0].ID} data={d} onFullscreenViewClick={() => onFullscreenViewClick(d[0].ID, d)} />
                    })}
                </Grid>
        }
        {dialog}
    </div>
}

export default HomePage;