import { useEffect, useRef, useState } from 'react';

import AgGrid from "components/AgGrid/AgGrid";
//Active Questions ColumnDefs
import { getActiveQuestionsColumnDefs } from './ActiveQuestionsColumnDefs/ActiveQuestionsColumnDefs';
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
import ActiveQuestionsRecordDialog from './ActiveQuestionsRecordDialog/ActiveQuestionsRecordDialog';
import { enqueueSnackbar } from 'notistack';
import SourceProfileSwitchPanel from 'components/SourceProfileSwitchPanel/SourceProfileSwitchPanel';

const GRID_REFRESH_INTERVAL_SECONDS = 60

const ActiveQuestionsPage = () => {
    const gridRef = useRef()
    const [rowData, setRowData] = useState(null)
    const [dialog, setDialog] = useState(null)
    const [sourceProfile, setSourceProfile] = useState({ sourceProfileID: null, sourceProfiles: [] })

    useEffect(() => {
        updateGrid(sourceProfile)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            if (!dialog)
                updateGrid(sourceProfile)
        }, GRID_REFRESH_INTERVAL_SECONDS * 1000)

        return () => {
            clearInterval(refreshInterval)
        }
    }, [dialog, sourceProfile])

    const updateGrid = (newSourceProfile) => {
        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.showLoadingOverlay()
        }

        ServiceManager.getActiveQuestions({ SourceProfileID: newSourceProfile?.sourceProfileID })
            .then(res => {
                setRowData(res)
            })
            .finally(() => {
                if (gridRef && gridRef.current && gridRef.current.api) {
                    gridRef.current.api.hideOverlay()
                }
            })
    }

    const handleDialogClose = () => {
        setDialog(null)
    }

    const onAnswerClick = (event, { data }) => {
        const { QuestionID, ReplyEmail, Name } = data
        const currentSourceProfile = sourceProfile.sourceProfiles.find(item => item.SourceProfileID === sourceProfile.sourceProfileID)

        setDialog(
            <ActiveQuestionsRecordDialog
                sourceProfile={currentSourceProfile}
                data={data}
                onSubmitClick={(event, { startLoading, stopLoading, formDataKeyValue }) => {
                    startLoading()
                    const { Email, ...restCurrentSourceProfile } = currentSourceProfile

                    ServiceManager.sendAnswer({ QuestionID, ReplyEmail, FromEmail: Email, ReplyName: Name, ...restCurrentSourceProfile, ...formDataKeyValue })
                        .then(() => {
                            enqueueSnackbar({ message: 'Email was sent successfully!', variant: 'success' })
                            updateGrid(sourceProfile)
                            handleDialogClose()
                        })
                        .catch(() => {
                            enqueueSnackbar({ message: 'Something went wrong...', variant: 'error' })
                        })
                        .finally(() => {
                            stopLoading()
                        })
                }}
                onClose={handleDialogClose}
            />
        )
    }

    const onSkipClick = (event, { startLoading, stopLoading, data }) => {
        const { QuestionID } = data
        startLoading()
        ServiceManager.skipQuestion({ QuestionID })
            .then(() => {
                updateGrid(sourceProfile)
                stopLoading()
            })
            .catch(() => {
                stopLoading()
            })
    }

    const onSourceProfileChange = ({ SourceProfileID, SourceProfiles }) => {
        const newSourceProfile = {
            sourceProfileID: SourceProfileID,
            sourceProfiles: SourceProfiles,
        }

        setSourceProfile(newSourceProfile)
        updateGrid(newSourceProfile)
    }

    return <div>
        <SourceProfileSwitchPanel onChange={onSourceProfileChange} />
        <AgGrid ref={gridRef} showTotalRow={true} rowData={rowData} columnDefs={getActiveQuestionsColumnDefs({ showAnswerButton: sourceProfile.sourceProfileID !== null, onAnswerClick, onSkipClick })} height={'70vh'} />        
        {dialog}
    </div>
}

export default ActiveQuestionsPage;