import { useEffect, useState } from "react";
//components
import AgGrid from "components/AgGrid/AgGrid";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//ColumnDefs
import { getSourceProfileManagerColumnDefs } from './SourceProfileManagerColumnDefs/SourceProfileManagerColumnDefs';
import Button from "lib/component/ATForm/UI/Button/Button";
//MUI
import { Grid } from "@mui/material";
//Record Dialog
import SourceProfileManagerRecordDialog from "./SourceProfileManagerRecordDialog/SourceProfileManagerRecordDialog";
//Snackbar
import { useSnackbar } from "notistack";

const SourceProfileManagerPage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [rowData, setRowData] = useState(null)
    const [dialog, setDialog] = useState(null)

    useEffect(() => {
        updateGrid()
    }, [])

    const updateGrid = () => {
        ServiceManager.getSourceProfiles()
            .then(res => {
                setRowData(res)
            })
    }

    const onAddClick = () => {
        const newDialog = <SourceProfileManagerRecordDialog
            title={'Add new SourceProfile'}
            onClose={handleDialogClose}
            onSubmitClick={(event, { startLoading, stopLoading, formData, formDataKeyValue }) => {
                startLoading()
                ServiceManager.addSourceProfile(formDataKeyValue)
                    .then((res) => {
                        updateGrid()
                        handleDialogClose()
                    })
                    .finally(() => {
                        stopLoading()
                    })
            }}
        />

        setDialog(newDialog)
    }

    const onRemoveClick = (event, { data }) => {
        enqueueSnackbar('Are you sure you want to delete this SourceProfile?', {
            variant: 'areYouSure',
            persist: true,
            onYesClick: (event, { startLoading, stopLoading, handleCloseSnackbar }) => {
                startLoading()
                ServiceManager.deleteSourceProfile({ SourceProfileID: data.SourceProfileID })
                    .then(res => {
                        updateGrid()
                        handleCloseSnackbar()
                    })
                    .finally(() => {
                        stopLoading()
                    })
            },
        })
    }

    const onEditClick = (event, { data }) => {
        ServiceManager.getSourceProfiles({ SourceProfileID: data.SourceProfileID })
            .then(res => {
                const currentSourceProfileData = res[0]
                console.log('currentSourceProfileData,', currentSourceProfileData)

                const newDialog = <SourceProfileManagerRecordDialog
                    title={`Edit SourceProfile`}
                    onClose={handleDialogClose}
                    onSubmitClick={(event, { startLoading, stopLoading, formData, formDataKeyValue }) => {
                        startLoading()
                        ServiceManager.editSourceProfile({ ...formDataKeyValue, SourceProfileID: data.SourceProfileID })
                            .then((res) => {
                                updateGrid()
                                handleDialogClose()
                            })
                            .finally(() => {
                                stopLoading()
                            })
                    }}
                    defaultValue={currentSourceProfileData}
                />

                setDialog(newDialog)
            })
    }

    const handleDialogClose = () => {
        setDialog(null)
    }

    return (
        <div style={{}}>
            <AgGrid
                columnDefs={getSourceProfileManagerColumnDefs({ onRemoveClick, onEditClick })}
                rowData={rowData}
            />
            <Grid container item md={3} sx={{ marginTop: '10px' }}>
                <Button label={'Add new SourceProfile'} variant={'contained'} onClick={onAddClick} />
            </Grid>
            {dialog}
        </div>
    )
}

export default SourceProfileManagerPage;