import React from 'react';

//MUI
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

//Constants
import Chart from '../Chart/Chart';

const ChartDialog = ({ data, onClose }) => {
    return (<Dialog open={true} onClose={onClose} fullScreen={true}>
        <DialogTitle>
            {data[0].ParentTitle}
            <Tooltip title={'Close'} onClick={onClose} >
                <IconButton sx={{ color: 'primary', position: 'absolute', right: '12px', top: '14px' }} >
                    <CancelIcon sx={{ fontSize: '32px' }} />
                </IconButton>
            </Tooltip>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                <Chart data={data} fullscreen={true} />
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
    </Dialog>);
}

export default ChartDialog;