import React, { useState } from 'react';

//MUI
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//Containers
import MasterPage from 'MasterPage/MasterPage';
//Moment
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
//Date Picker Localization
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//Notistack
import { SnackbarProvider } from 'notistack';
//Error Handling
import { ErrorBoundary } from 'react-error-boundary'
import { Button } from '@mui/material';
//ATForm
//Context
import { ATFormContextProvider } from 'lib';
import { BrowserRouter } from 'react-router-dom';
//ClasslessSnackbar Snackbar
import { SnackbarUtilsConfigurator } from './ClasslessSnackbar/ClasslessSnackbar';
//Redux
import { Provider } from 'react-redux'
//Redux store
import store from './app/store';
//Notstack custom variations
import AreYouSure from 'notistack/CustomVariations/AreYouSure/AreYouSure';
import { ColorPalette } from 'constants/ColorPalette/ColorPalette';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(',')
  },
  palette: {
    primary: {
      main: ColorPalette.mechaButtonBlue,
    },
    secondary: {
      main: ColorPalette.mechaButtonGreen,
      contrastText: 'white'
    },
    mechaYellow: ColorPalette.paletteColor_mechaButtonYellow,
    mechaGrey: ColorPalette.paletteColor_mechaButtonGrey,
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff85',
          backdropFilter: 'blur(18px)',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: ColorPalette.mechaButtonGrey
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '15px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          padding: '25px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '7px',
          textTransform: 'none',
          fontWeight: 'normal'
        },
      },
    },
  },
});

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '100px' }}>
      <p style={{ color: 'red' }}>Something went wrong:</p>
      <pre style={{ fontWeight: 'bold' }}>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  )
}

function App() {
  const [appKey, setAppKey] = useState('MasterPage')

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            Components={{
              areYouSure: AreYouSure
            }}
          >
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                setAppKey(Math.random())
              }}
            >
              <ATFormContextProvider>
                <BrowserRouter>
                  <SnackbarUtilsConfigurator />
                  <Provider store={store}>
                    <MasterPage key={appKey} />
                  </Provider>
                </BrowserRouter>
              </ATFormContextProvider>
            </ErrorBoundary>
          </SnackbarProvider>
        </LocalizationProvider >
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
