import { ATFormDialog, formBuilder } from 'lib';
import { useEffect, useRef } from 'react';

//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Snackbar
import { useSnackbar } from 'notistack'
import { Refresh } from '@mui/icons-material';
const Columns = [
    {
        id: 'model',
        label: 'Model',
        type: 'ComboBox',
        options: [
            {
                ID: 'text-davinci-003',
                Title: 'text-davinci-003',
            },
            {
                ID: 'gpt-3.5-turbo',
                Title: 'gpt-3.5-turbo',
            }
        ],
    },
    {
        id: 'temperature',
        label: 'Temperature',
        type: 'TextBox',
    },
    {
        id: 'max_tokens',
        label: 'MaximumLength',
        type: 'TextBox',
    },
    {
        id: 'top_p',
        label: 'TopP',
        type: 'TextBox',
    },
    {
        id: 'frequency_penalty',
        label: 'FrequencyPenalty',
        type: 'TextBox',
    },
    {
        id: 'presence_penalty',
        label: 'PresancePenalty',
        type: 'TextBox',
    },
    {
        id: 'best_of',
        label: 'BestOf',
        type: 'TextBox',
    },
]

const defaultValueObject = {
    model: 'gpt-3.5-turbo',
    temperature: '1.0',
    max_tokens: '250',
    top_p: '1',
    frequency_penalty: '0.7',
    presence_penalty: '0.7',
    best_of: '2',
}

export const OpenAIConfigDefaultValue = defaultValueObject

const OpenAIConfigDialog = ({ onClose, onChange }) => {
    const { enqueueSnackbar } = useSnackbar()
    const formRef = useRef(null)
    const mFormDataKeyValue = useRef(null)

    useEffect(() => {
        ServiceManager.GetUserConfigs()
            .then(res => {
                formRef.current.reset(res)
            })
    }, [])

    const onFormChange = ({ formData, formDataKeyValue }) => {
        mFormDataKeyValue.current = formDataKeyValue
    }

    const onResetToDefaultClick = () => {
        formRef.current.reset(OpenAIConfigDefaultValue)
    }

    const onSaveButtonClick = (event, { startLoading, stopLoading }) => {
        startLoading()
        ServiceManager.SaveUserConfigs({ Configs: JSON.stringify(mFormDataKeyValue.current) })
            .then(res => {
                onChange(mFormDataKeyValue.current)
                enqueueSnackbar('Config Saved Successfully', { variant: 'success' })
            })
            .catch((err) => {
                enqueueSnackbar('Saving The Config Failed', { variant: 'error' })
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <ATFormDialog
            ref={formRef}
            onChange={onFormChange}
            onClose={onClose}
            getActions={
                (oldActions) => {
                    return [
                        {
                            id: 'Save',
                            color: 'primary',
                            onClick: onSaveButtonClick,
                            variant: 'contained',
                            grid: {
                                xs: 12,
                                md: 12,
                            },
                            sx: { borderRadius: 50 },
                        },
                        {
                            id: 'Cancel',
                            color: 'mechaGrey',
                            onClick: (...props) => onClose(...props),
                            variant: 'contained',
                            grid: {
                                xs: 12,
                                md: 12,
                            },
                            sx: { borderRadius: 50 },
                        }
                    ]
                }
            }
        >
            {
                [
                    ...Columns.map(item => {
                        return {
                            ...item,
                            xs: 6
                        }
                    }),
                    formBuilder.createButton({ id: 'Reset to default', variant: 'contained', startIcon: <Refresh />, xs: 6, md: 6, sx: { borderRadius: 50, height: '52px' }, color: 'secondary', onClick: onResetToDefaultClick })
                ]
            }
        </ATFormDialog>
    )
}

export default OpenAIConfigDialog;