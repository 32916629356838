
import { useEffect, useRef, useState } from "react";

import { Grid } from "@mui/material";
import { ATForm, formBuilder } from "lib";

import { UserManagerColumns } from 'constants/Columns/Columns';
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Dialogs
import OpenAIConfigDialog from "./OpenAIConfigDialog/OpenAIConfigDialog";
import QuestionsBlacklistDialog from "./QuestionsBlacklistDialog/QuestionsBlacklistDialog";

const MyAccountPage = ({ user }) => {
    const formRef = useRef(null)
    const [dialog, setDialog] = useState(null)

    useEffect(() => {
        ServiceManager.getUsers({ UserID: user.UserID })
            .then(res => {
                formRef.current.reset(res[0])
            })
    }, [user])

    const handeDialogClose = () => {
        setDialog(null)
    }

    const onShowOpenAIConfigDialogClick = () => {
        setDialog(
            <OpenAIConfigDialog
                onChange={(newConfig) => {
                    localStorage.setItem('openAIConfig', JSON.stringify(newConfig))
                }}
                onClose={handeDialogClose}
            />
        )
    }

    const onOpenQuestionsBlacklistClick = () => {
        setDialog(
            <QuestionsBlacklistDialog
                onClose={handeDialogClose}
            />
        )
    }

    return <div>
        <Grid container spacing={2}>
            <ATForm ref={formRef}>
                {
                    formBuilder.createColumnBuilder(UserManagerColumns)
                        .remove(['Password', 'IsAdmin'])
                        .map(item => ({ ...item, md: 6 }))
                        .override({
                            'Headshot': { md: 12, flexGridProps: { textAlign: 'center' } }
                        })
                        .add([
                            formBuilder.createButton({ id: 'Open OpenAI Config', onClick: onShowOpenAIConfigDialogClick, variant: 'contained', md: 12, sx: { textTransform: 'none' } }),
                            formBuilder.createButton({ id: 'Open Questions Blacklist', onClick: onOpenQuestionsBlacklistClick, variant: 'outlined', md: 12, sx: { textTransform: 'none' } })
                        ])
                        .build()
                }
            </ATForm>
        </Grid>
        {dialog}
    </div>
}

export default MyAccountPage;