import { useEffect, useState } from "react";
//components
import AgGrid from "components/AgGrid/AgGrid";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//ColumnDefs
import { getUserManagerColumnDefs } from './UserManagerColumnDefs/UserManagerColumnDefs';
import Button from "lib/component/ATForm/UI/Button/Button";
//MUI
import { Grid } from "@mui/material";
//Record Dialog
import UserManagerRecordDialog from "./UserManagerRecordDialog/UserManagerRecordDialog";
//Snackbar
import { useSnackbar } from "notistack";
import { OpenAIConfigDefaultValue } from "../MyAccountPage/OpenAIConfigDialog/OpenAIConfigDialog";
//Components
import UserSourceProfileDialog from "./UserSourceProfileDialog/UserSourceProfileDialog";

const UserManagerPage = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [rowData, setRowData] = useState(null)
    const [dialog, setDialog] = useState(null)

    useEffect(() => {
        updateGrid()
    }, [])

    const updateGrid = () => {
        ServiceManager.getUsers()
            .then(res => {
                setRowData(res)
            })
    }

    const onAddClick = () => {
        const newDialog = <UserManagerRecordDialog
            title={'Add new user'}
            onClose={handleDialogClose}
            onSubmitClick={(event, { startLoading, stopLoading, formData, formDataKeyValue }) => {
                startLoading()

                ServiceManager.addUser({ ...formDataKeyValue, OpenAIConfig: JSON.stringify(OpenAIConfigDefaultValue) })
                    .then((res) => {
                        updateGrid()
                        handleDialogClose()
                    })
                    .finally(() => {
                        stopLoading()
                    })
            }}
        />

        setDialog(newDialog)
    }

    const onRemoveClick = (event, { data }) => {
        enqueueSnackbar('Are you sure you want to delete this user?', {
            variant: 'areYouSure',
            persist: true,
            onYesClick: (event, { startLoading, stopLoading, handleCloseSnackbar }) => {
                startLoading()
                ServiceManager.deleteUser({ UserID: data.UserID })
                    .then(res => {
                        updateGrid()
                        handleCloseSnackbar()
                    })
                    .finally(() => {
                        stopLoading()
                    })
            },
        })
    }

    const onEditClick = (event, { data }) => {
        ServiceManager.getUsers({ UserID: data.UserID })
            .then(res => {
                const currentUserData = res[0]
                console.log('currentUserData,', currentUserData)

                const newDialog = <UserManagerRecordDialog
                    title={`Edit user`}
                    onClose={handleDialogClose}
                    onSubmitClick={(event, { startLoading, stopLoading, formData, formDataKeyValue }) => {
                        startLoading()
                        ServiceManager.editUser({ ...formDataKeyValue, UserID: data.UserID })
                            .then((res) => {
                                updateGrid()
                                handleDialogClose()
                            })
                            .finally(() => {
                                stopLoading()
                            })
                    }}
                    defaultValue={currentUserData}
                />

                setDialog(newDialog)
            })
    }

    const handleDialogClose = () => {
        setDialog(null)
    }

    const onEditUserSourceProfileClick = (event, { data }) => {
        const { UserID } = data

        ServiceManager.getUserSourceProfiles({ UserID })
            .then(res => {
                setDialog(
                    <UserSourceProfileDialog
                        data={data}
                        defaultValue={res}
                        onClose={handleDialogClose}
                        onSaveClick={(e, { startLoading, stopLoading, selectedRows }) => {
                            startLoading()
                            ServiceManager.saveUserSourceProfiles({ UserID, SourceProfiles: selectedRows.map(item => ({ SourceProfileID: item.SourceProfileID })) })
                                .then(res => {
                                    enqueueSnackbar('Source profile was saved successfully!', { variant: 'success' })
                                    handleDialogClose()
                                })
                                .finally(() => {
                                    stopLoading()
                                })
                        }}
                    />
                )
            })
            .finally(() => {

            })
    }

    return (
        <div style={{}}>
            <AgGrid
                columnDefs={getUserManagerColumnDefs({ onRemoveClick, onEditClick, onEditUserSourceProfileClick })}
                rowData={rowData}
            />
            <Grid container item md={2} sx={{ marginTop: '10px' }}>
                <Button label={'Add new user'} variant={'contained'} onClick={onAddClick} />
            </Grid>
            {dialog}
        </div>
    )
}

export default UserManagerPage;