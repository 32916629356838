import { useRef } from 'react';

import 'chart.js/auto';
import { Chart as ReactChart } from 'react-chartjs-2';
//MUI
import { Grid, IconButton } from '@mui/material';
import { UnfoldMore } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

const Chart = ({ data, onFullscreenViewClick, fullscreen }) => {
    const componentRef = useRef(null)

    const firstRow = data[0]
    const { ParentTitle, ParentType, ParentLabels, ParentOptions, ParentDataLabel, GridSize } = firstRow

    const datasets = data.map(d => {
        const { DataSetBackgroundColor, DataSetData, DataSetLabel, DataSetOptions } = d

        let backgroundColor = null
        try {
            if (DataSetBackgroundColor.includes("context")) {
                // eslint-disable-next-line
                backgroundColor = eval(DataSetBackgroundColor)
            }
            else
                backgroundColor = JSON.parse(DataSetBackgroundColor)
        }
        catch (err) {
            backgroundColor = DataSetBackgroundColor
        }

        return {
            ...(DataSetOptions ? JSON.parse(DataSetOptions) : {}),
            data: JSON.parse(DataSetData),
            label: DataSetLabel,
            backgroundColor: backgroundColor,
        }
    })

    const options = {
        plugins: {
            title: {
                display: true,
                text: ParentTitle,
            }
        },
        //For a fixed height to work this needs to be set to false
        maintainAspectRatio: false,
        ...JSON.parse(ParentOptions),
    }

    if (ParentDataLabel) {
        options['scales'] = {
            ...(options.scales || {}),
            y: {
                ...(options.scales?.y || {}),
                title: {
                    ...(options.scales?.y?.title || {}),
                    display: true,
                    text: ParentDataLabel,
                    position: 'top'
                }
            }
        }
    }

    return <Grid item xs={12} md={GridSize || 6} xl={GridSize || 6} sx={{ position: 'relative' }} {...(fullscreen ? { md: 12, xl: 12 } : {})}>
        <Paper sx={{ boxShadow: '1px 4px 4px 1px rgb(0 0 0 / 10%)' }} elevation={0} ref={componentRef} >
            <ReactChart height={fullscreen ? window.innerHeight - 260 : 360} type={ParentType} data={{ labels: JSON.parse(ParentLabels), datasets: datasets }} options={options} />
        </Paper>
        {!fullscreen &&
            <Tooltip title={'Fullscreen'} onClick={onFullscreenViewClick} >
                <IconButton sx={{ color: '#717171', position: 'absolute', right: '12px', top: '22px', rotate: '45deg' }} >
                    <UnfoldMore />
                </IconButton>
            </Tooltip>
        }
    </Grid>
}

export default Chart


