import { useState } from 'react';

import MUIIconButton from '@mui/material/IconButton';

const IconButton = ({ onClick, icon, data }) => {
    const [loading, setLoading] = useState(false)

    const startLoading = () => {
        setLoading(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    return <MUIIconButton disabled={loading} onClick={(event) => onClick(event, { startLoading, stopLoading, data })}>{icon}</MUIIconButton>
}

export default IconButton;