import { Button, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";

const SourceProfileButton = ({ onClick, isSelected, Name, Company, Headshot, SourceProfileID }) => {
    const buttonStyle = {
        display: 'inline-block',
        textAlign: '-webkit-center',
        marginLeft: '2px',
        marginTop: '2px',
        width: '118px',
        height: '120px',
        padding: '1px',
        textTransform: 'none',
        ...(!isSelected ? {} : {
            border: 'double 1px transparent',
            borderRadius: '20px',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            backgroundImage: 'linear-gradient(white, white), linear-gradient(to right, #46BFAE, #2865B3)'
            // 'linear-gradient(#46BFAE, #2865B3) 30'
        })
    }

    return <Button sx={buttonStyle} onClick={(event) => onClick(event, { SourceProfileID })} variant={'text'} color={isSelected ? 'secondary' : 'primary'}>
        <div>
            <Avatar alt={Name} src={Headshot} sx={{ width: '50px', height: '50px' }} />
            <Typography sx={{ fontSize: '11px', color: 'black', fontWeight: 'medium' }}>
                {Name}
            </Typography>
            <Typography sx={{ fontSize: '7px', color: 'black', fontWeight: 'regular' }}>
                {Company}
            </Typography>
        </div>
    </Button>
}

export default SourceProfileButton;