// import ServiceManager from 'ServiceManager/ServiceManager';
import axios from './AxiosServer/AxiosServer';

// const MAX_CONCURRENT_REQUESTS = 1;

const API_VERSION = 'api'
const APPLICATION_NAME = 'MechaDashboard'

const RBODY = {
    createLogin: (username, password) => {
        return {
            Username: username,
            Password: password,
        }
    },
    createInvoke: (database, schema, procedureName, { ...props } = {}) => {
        return {
            ProcName: `${database}.${schema}.${procedureName}`,
            ...props,
        }
    },
    createWS: (method, url, { api_key_name, body } = {}) => {
        return {
            method: method,
            url: url,
            api_key_name: api_key_name,
            body: body,
        }
    },
}

export const URL = {
    createLogin: () => `${API_VERSION}/${APPLICATION_NAME}/Login`,
    createInvoke: (serviceName) => `${API_VERSION}/${APPLICATION_NAME}/Invoke/${serviceName}`,
    createWS: (serviceName) => `${API_VERSION}/${APPLICATION_NAME}/WS/${serviceName}`,
    createRefreshToken: () => `${API_VERSION}/${APPLICATION_NAME}/RefreshToken`
}

class ServiceManager {
    login = ({ username, password }) => {
        //'Admin', 'VeryLongPassword#123456'
        const requestBody = RBODY.createLogin(username, password)

        return axios.post(URL.createLogin(), requestBody)
            .then(res => {
                return {
                    ...res.data.Data[0],
                    Token: res.data.Token,
                    RefreshToken: res.data.RefreshToken
                }
            })
    }

    //OpenAI
    getAnswer = ({ question, sourceProfileTitle }) => {
        const openAIConfig = localStorage.getItem('openAIConfig')

        return axios.post(`/${API_VERSION}/getAnswerList`, { sourceProfileTitle, questionList: [{ question: question, id: question }], openAIConfig: (openAIConfig && openAIConfig !== '') ? JSON.parse(openAIConfig) : {} })
            .then(res => res.data[0])
    }

    //Answer Page
    sendAnswer = ({ SourceProfileID, QuestionID, AnswerHeadline, AnswerBody, ReplyEmail, ReplyName, FromEmail, ...restProps }) => {
        return axios.post(`/${API_VERSION}/sendAnswer`, { SourceProfileID, QuestionID, AnswerHeadline, AnswerBody, ReplyEmail, ReplyName, FromEmail, ...restProps })
            .then(res => res.data[0])
    }

    skipQuestion = ({ QuestionID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'skipQuestion', { QuestionID })
        return axios.post(URL.createInvoke('skipQuestion'), requestBody)
            .then(res => res.data[0])
    }

    UnskipQuestion = ({ QuestionID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'UnskipQuestion', { QuestionID })
        return axios.post(URL.createInvoke('UnskipQuestion'), requestBody)
            .then(res => res.data[0])
    }

    //Users
    getUsers = ({ UserID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getUsers', { UserID })
        return axios.post(URL.createInvoke('getUsers'), requestBody)
            .then(res => res.data[0])
    }

    addUser = ({ ...params }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'addUser', { ...params })

        return axios.post(URL.createInvoke('addUser'), requestBody)
            .then(res => res.data[0])
    }

    editUser = ({ UserID, ...params }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'editUser', { UserID, ...params })
        return axios.post(URL.createInvoke('editUser'), requestBody)
            .then(res => res.data[0])
    }

    deleteUser = ({ UserID }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'deleteUser', { UserID })
        return axios.post(URL.createInvoke('deleteUser'), requestBody)
            .then(res => res.data[0])
    }

    //Source profiles
    getSourceProfiles = ({ SourceProfileID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getSourceProfiles', { SourceProfileID })
        return axios.post(URL.createInvoke('getSourceProfiles'), requestBody)
            .then(res => res.data[0])
    }

    addSourceProfile = ({ ...params }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'addSourceProfile', { ...params })
        return axios.post(URL.createInvoke('addSourceProfile'), requestBody)
            .then(res => res.data[0])
    }

    editSourceProfile = ({ SourceProfileID, ...params }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'editSourceProfile', { SourceProfileID, ...params })
        return axios.post(URL.createInvoke('editSourceProfile'), requestBody)
            .then(res => res.data[0])
    }

    deleteSourceProfile = ({ SourceProfileID }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'deleteSourceProfile', { SourceProfileID })
        return axios.post(URL.createInvoke('deleteSourceProfile'), requestBody)
            .then(res => res.data[0])
    }
    //Questions
    getActiveQuestions = ({ SourceProfileID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getActiveQuestions', { SourceProfileID })
        return axios.post(URL.createInvoke('getActiveQuestions'), requestBody)
            .then(res => res.data[0])
    }

    getAnsweredQuestions = ({ SourceProfileID } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getAnsweredQuestions', { SourceProfileID })
        return axios.post(URL.createInvoke('getAnsweredQuestions '), requestBody)
            .then(res => res.data[0])
    }

    getSkippedQuestions = () => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getSkippedQuestions')
        return axios.post(URL.createInvoke('getSkippedQuestions '), requestBody)
            .then(res => res.data[0])
    }
    //Configs
    SaveUserConfigs = ({ UserConfigTypeID = 1, Configs } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'SaveUserConfigs', { UserConfigTypeID, Configs })
        return axios.post(URL.createInvoke('SaveUserConfigs'), requestBody)
    }

    GetUserConfigs = ({ UserConfigTypeID = 1 } = {}) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'GetUserConfigs', { UserConfigTypeID })
        return axios.post(URL.createInvoke('GetUserConfigs'), requestBody)
            .then(res => res.data?.[0]?.[0]?.Configs ? JSON.parse(res.data[0][0].Configs) : null)
    }

    //Dashboard
    getCharts = () => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getCharts')
        return axios.post(URL.createInvoke('getCharts'), requestBody)
            .then(res => res.data)
    }

    //User Source Profile
    getUserSourceProfiles = ({ UserID }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'getUserSourceProfiles', { UserID })
        return axios.post(URL.createInvoke('getUserSourceProfiles'), requestBody)
            .then(res => res.data?.[0])
    }

    saveUserSourceProfiles = ({ UserID, SourceProfiles }) => {
        const requestBody = RBODY.createInvoke('WDB', 'API', 'saveUserSourceProfiles', { UserID, SourceProfiles: JSON.stringify(SourceProfiles) })
        return axios.post(URL.createInvoke('saveUserSourceProfiles'), requestBody)
            .then(res => res.data)
    }
}

const instance = new ServiceManager()

export const clearLocalStorage = () => {
    localStorage.setItem('token', '')
    localStorage.setItem('refreshToken', '')
    localStorage.setItem('user', '')
}

export const updateLocalStorage = (res) => {
    const user = {
        ...res,
    }

    localStorage.setItem('token', user.Token)
    localStorage.setItem('refreshToken', user.RefreshToken)
    localStorage.setItem('user', JSON.stringify(user))

    return {
        token: user.Token,
        refreshToken: user.RefreshToken,
        user,
    }
}

export default instance;