import { SourceProfileManagerColumns } from "constants/Columns/Columns";
import { ATFormDialog, formBuilder } from "lib"
import { useCallback, useState } from "react";

const SourceProfileManagerRecordDialog = ({ defaultValue, ...restProps }) => {
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [displayName, setDisplayName] = useState('')

    const refCallback = useCallback((ref) => {
        if (ref)
            ref.reset(defaultValue)
    }, [defaultValue])

    const updateDisplayName = (name, company) => {
        const newDisplayName = (name && company) ? `${name} (${company})` : ''

        setDisplayName(newDisplayName)
    }

    return <ATFormDialog ref={refCallback} {...restProps}>
        {
            [
                formBuilder.createAvatar({ id: 'Headshot', ...SourceProfileManagerColumns.find(item => item.id === 'Headshot').uiProps }),
                formBuilder.createTextBox({ id: 'Name', ...SourceProfileManagerColumns.find(item => item.id === 'Name').uiProps, onChange: (event) => { setName(event.target.value); updateDisplayName(event.target.value, company); } }),
                formBuilder.createTextBox({ id: 'Company', ...SourceProfileManagerColumns.find(item => item.id === 'Company').uiProps, onChange: (event) => { setCompany(event.target.value); updateDisplayName(name, event.target.value); } }),
                formBuilder.createTextBox({ id: 'DisplayName', ...SourceProfileManagerColumns.find(item => item.id === 'DisplayName').uiProps, value: displayName }),
                SourceProfileManagerColumns.filter(item => item.uiProps && !['Name', 'Company', 'DisplayName', 'Headshot'].includes(item.id)).map(item => {
                    return {
                        id: item.id,
                        md: 3,
                        label: item.label,
                        ...item.uiProps,
                    }
                })
            ]
        }
    </ATFormDialog>
}

export default SourceProfileManagerRecordDialog;