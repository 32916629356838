
import { QuestionsColumns } from 'constants/Columns/Columns';
//CellRenderers
import Button from 'components/AgGrid/CellRenderers/Button/Button';

export const getActiveQuestionsColumnDefs = ({ showAnswerButton, onAnswerClick, onSkipClick }) => {

    return [
        ...QuestionsColumns.map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),                
            }
        }),        
        {
            cellRenderer: Button,
            cellRendererParams: {
                color: 'secondary',
                onClick: onAnswerClick,
                disabled: !showAnswerButton,
            },
            field: 'Answer',
            headerName: 'Answer',
            width: 100,
            // pinned: 'right',
        },
        {
            cellRenderer: Button,
            cellRendererParams: {                
                onClick: onSkipClick,
            },
            field: 'Skip',
            headerName: 'Skip',
            width: 100,
            // pinned: 'right',
        },
    ]
}