
import { SourceProfileManagerColumns } from 'constants/Columns/Columns';
//CellRenderers
import IconButton from 'components/AgGrid/CellRenderers/IconButton/IconButton';
//SVG Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//Color Palette
import { ColorPalette } from "constants/ColorPalette/ColorPalette";

export const getSourceProfileManagerColumnDefs = ({ onRemoveClick, onEditClick }) => {

    return [
        ...SourceProfileManagerColumns.map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),
            }
        }),        
        {
            cellRenderer: IconButton,
            cellRendererParams: {
                icon: <EditOutlinedIcon sx={{ color: ColorPalette.mechaEditGrey }} />,
                onClick: onEditClick,
            },
            field: 'Edit',
            headerName: 'Edit',
            width: 79,
            filter: false,
            // pinned: 'left',
        },
        {
            cellRenderer: IconButton,
            cellRendererParams: {
                icon: <DeleteForeverOutlinedIcon sx={{ color: ColorPalette.remove }} />,
                onClick: onRemoveClick,
            },
            field: 'Remove',
            headerName: 'Remove',
            width: 100,
            filter: false,
            // pinned: 'left',
        },
    ]
}