import { configureStore } from '@reduxjs/toolkit';

import { authenticationReducer } from './slices/authenticationSlice/authenticationSlice';
import { enumsReducer } from './slices/enumsSlice/enumsSlice';

export default configureStore({
  reducer: {
    authentication: authenticationReducer,
    enums: enumsReducer,
  },
});