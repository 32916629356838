
import { UserManagerColumns } from 'constants/Columns/Columns';
//CellRenderers
import IconButton from 'components/AgGrid/CellRenderers/IconButton/IconButton';
//SVG Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
//Color Palette
import { ColorPalette } from "constants/ColorPalette/ColorPalette";
import { ColumnDefTemplates } from 'lib/component/ATAgGrid/ColumnDefTemplates/ColumnDefTemplates';

export const getUserManagerColumnDefs = ({ onRemoveClick, onEditClick, onEditUserSourceProfileClick }) => {

    return [
        ...UserManagerColumns.filter(item => item.id !== 'Password').map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),
            }
        }),
        ColumnDefTemplates.createButton({ onClick: onEditUserSourceProfileClick, field: 'EditSourceProfile', headerName: 'Source Profile', variant: 'contained', color: 'secondary', filter: false, getCellRendererParams: (data) => ({ disabled: data.IsAdmin }) }),
        {
            cellRenderer: IconButton,
            cellRendererParams: {
                icon: <EditOutlinedIcon sx={{ color: ColorPalette.mechaEditGrey }} />,
                onClick: onEditClick,
            },
            field: 'Edit',
            headerName: 'Edit',
            width: 80,
            // pinned: 'left',
        },
        {
            cellRenderer: IconButton,
            cellRendererParams: {
                icon: <DeleteForeverOutlinedIcon sx={{ color: ColorPalette.remove }} />,
                onClick: onRemoveClick,
            },
            field: 'Remove',
            headerName: 'Remove',
            width: 110,
            // pinned: 'left',
        },
    ]
}