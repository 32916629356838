import { useState, useCallback, useEffect, useRef } from "react";

import { ATFormDialog, formBuilder } from "lib"

import { ActiveQuestionsRecordColumns } from "constants/Columns/Columns";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
import { Refresh } from "@mui/icons-material";

const ActiveQuestionsRecordDialog = ({ sourceProfile, defaultValue, data, onSubmitClick, onClose }) => {
    const formRef = useRef(null)
    const mFormDataKeyValue = useRef(null)
    const [answerList, setAnswerList] = useState([])
    const [answerIndex, setAnswerIndex] = useState(0)
    const [loading, setLoading] = useState(true)

    console.log('sourceProfile', sourceProfile)

    useEffect(() => {
        const { Title } = sourceProfile || {}

        ServiceManager.getAnswer({ question: data.Body, sourceProfileTitle: Title })
            .then(res => {
                setAnswerList([res])
                updateForm(0, [res])
            })
            .finally(() => {
                getNewAnswer()
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    const getNewAnswer = () => {
        const { Title } = sourceProfile || {}

        return ServiceManager.getAnswer({ question: data.Body, sourceProfileTitle: Title })
            .then(res => {
                setAnswerList((prevAnswerList) => {
                    const newAnswerList = [
                        ...prevAnswerList,
                        res
                    ]

                    return newAnswerList
                })
            })
    }

    const refCallback = useCallback((ref) => {
        if (ref) {
            ref.reset(defaultValue)
            formRef.current = ref
        }
    }, [defaultValue])

    const onRefreshButtonClick = (event, { startLoading, stopLoading }) => {
        let newAnswerIndex = answerIndex + 1

        if (newAnswerIndex < answerList.length) {
            if (newAnswerIndex === answerList.length - 1) {
                getNewAnswer()
            }
        }
        else {
            newAnswerIndex = 0
        }

        setAnswerIndex(newAnswerIndex)
        updateForm(newAnswerIndex, answerList)
    }

    const onPartialRefreshClick = (event, { startLoading, stopLoading }) => {
        if (mFormDataKeyValue.current) {
            startLoading()
            const { AnswerHeadline, AnswerBody } = mFormDataKeyValue.current

            ServiceManager.getAnswer({ question: AnswerBody })
                .then(res => {
                    formRef.current.reset({
                        AnswerHeadline: AnswerHeadline,
                        AnswerBody: AnswerBody + ' ' + res.answerList[0].text,
                    })
                })
                .finally(() => {
                    stopLoading()
                })
        }
    }

    const updateForm = (newAnswerIndex, answerList) => {
        formRef.current.reset({
            AnswerHeadline: answerList[newAnswerIndex].headlineAnswerList[0].text,
            AnswerBody: answerList[newAnswerIndex].answerList[0].text,
        })
    }

    const onInternalSubmitClick = (event, { startLoading, stopLoading, formData, formDataKeyValue }) => {
        if (onSubmitClick) {
            onSubmitClick(event, { startLoading, stopLoading, formData, formDataKeyValue })
        }
    }

    const onFormChange = ({ formDataKeyValue }) => {
        mFormDataKeyValue.current = formDataKeyValue
    }

    return <ATFormDialog
        onChange={onFormChange}
        titleStyle={{ borderBottom: '1px solid #1976d2' }}
        title={`You are answering as: ${sourceProfile.DisplayName}`}
        ref={refCallback}
        submitLoading={loading}
        onClose={onClose}
        getActions={(oldActions) => {
            const newActions = [
                formBuilder.createButton({ id: 'Submit', grid: { xs: 12, md: 8 }, onClick: onInternalSubmitClick, variant: 'contained', sx: { borderRadius: 50 }, color: 'secondary', disabled: loading }),
                formBuilder.createButton({ id: 'Refresh', grid: { xs: 12, md: 2 }, onClick: onRefreshButtonClick, variant: 'contained', sx: { borderRadius: 50 }, color: 'primary', startIcon: <Refresh sx={{ color: 'white' }} />, label: `(${answerIndex + 1} of ${answerList.length})`, disabled: loading }),
                formBuilder.createButton({ id: 'Partial Refresh', grid: { xs: 12, md: 2 }, onClick: onPartialRefreshClick, variant: 'contained', sx: { borderRadius: 50 }, label: < Refresh sx={{ color: 'black' }} />, color: 'mechaGrey', disabled: loading }),
            ]

            return newActions;
        }}
    >
        {
            [
                formBuilder.createLabel({ id: 'Summary', label: data.Summary, md: 12, sx: { fontWeight: 'bold' } }),
                formBuilder.createLabel({ id: 'Question', label: data.Body, md: 12 }),
                formBuilder.createTextBox({ id: 'AnswerHeadline', label: 'Headline', md: 12, disabled: loading }),
                formBuilder.createTextBox({ id: 'AnswerBody', label: 'Answer', ...ActiveQuestionsRecordColumns.find(item => item.id === 'Body').uiProps, md: 12, disabled: loading }),
            ]
        }
    </ATFormDialog >
}

export default ActiveQuestionsRecordDialog;