import { ATFormDialog, formBuilder } from 'lib';
import { useEffect, useRef } from 'react';

//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Snackbar
import { useSnackbar } from 'notistack'

const convertToKeyValue = (keywordsArray) => {
    const result = []

    if (keywordsArray && Array.isArray(keywordsArray)) {
        keywordsArray.forEach(item => {
            result.push(item.keyword)
        })
    }

    return result.join('|')
}

const convertToKeywordsArray = (value) => {
    const result = value.split('|')
    console.log('result', result)

    return result.filter(item => item !== '').map(item => ({ keyword: item }))
}

const QuestionsBlacklistDialog = ({ onClose }) => {
    const { enqueueSnackbar } = useSnackbar()
    const formRef = useRef(null)
    const mFormDataKeyValue = useRef(null)

    useEffect(() => {
        ServiceManager.GetUserConfigs({ UserConfigTypeID: 2 })
            .then(res => {
                const { body_block_keywords, outlet_block_keywords } = res || {}
                formRef.current.reset({ body_block_keywords: convertToKeyValue(body_block_keywords), outlet_block_keywords: convertToKeyValue(outlet_block_keywords) })
            })
    }, [])

    const onFormChange = ({ formData, formDataKeyValue }) => {
        mFormDataKeyValue.current = formDataKeyValue
    }

    const onSaveButtonClick = (event, { startLoading, stopLoading }) => {
        startLoading()
        const { body_block_keywords, outlet_block_keywords } = mFormDataKeyValue.current

        ServiceManager.SaveUserConfigs({ UserConfigTypeID: 2, Configs: JSON.stringify({ body_block_keywords: convertToKeywordsArray(body_block_keywords), outlet_block_keywords: convertToKeywordsArray(outlet_block_keywords) }) })
            .then(res => {
                enqueueSnackbar('Blacklist Saved Successfully', { variant: 'success' })
            })
            .catch((err) => {
                enqueueSnackbar('Saving The Blacklist Failed', { variant: 'error' })
            })
            .finally(() => {
                stopLoading()
            })
    }

    return (
        <ATFormDialog
            ref={formRef}
            onChange={onFormChange}
            onClose={onClose}
            getActions={
                (oldActions) => {
                    return [
                        {
                            id: 'Save',
                            color: 'secondary',
                            onClick: onSaveButtonClick,
                            variant: 'contained',
                            sx: { borderRadius: '50px' },
                            grid: {
                                xs: 12,
                                md: 12,
                            },
                        },
                        {
                            id: 'Cancel',
                            color: 'mechaGrey',
                            onClick: (...props) => onClose(...props),
                            variant: 'contained',
                            sx: { borderRadius: '50px' },
                            grid: {
                                xs: 12,
                                md: 12,
                            },
                        }
                    ]
                }
            }
        >
            {
                [
                    formBuilder.createTextBox({ id: 'body_block_keywords', label: 'Body Blacklist keywords (Split By "|")', md: 12 }),
                    formBuilder.createTextBox({ id: 'outlet_block_keywords', label: 'Outlet Blacklist keywords  (Split By "|")', md: 12 }),
                ]
            }
        </ATFormDialog>
    )
}

export default QuestionsBlacklistDialog;