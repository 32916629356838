import { UserManagerColumns } from "constants/Columns/Columns";
import { ATFormDialog } from "lib"
import {  useCallback } from "react";

const UserManagerRecordDialog = ({ defaultValue, ...restProps }) => {
    const refCallback = useCallback((ref) => {        
        if (ref)
            ref.reset(defaultValue)
    }, [defaultValue])

    return <ATFormDialog ref={refCallback} {...restProps}>
        {
            [
                UserManagerColumns.filter(item => item.uiProps).map(item => {
                    return {
                        id: item.id,
                        md: 3,
                        label: item.label,
                        ...item.uiProps,
                    }
                })
            ]
        }
    </ATFormDialog>
}

export default UserManagerRecordDialog;