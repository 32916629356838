// import * as utils from 'utils/utils';

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const sortByArray = (a, b, sortedArray) => {
    const indexA = sortedArray.indexOf(a)
    const indexB = sortedArray.indexOf(b)

    if (indexA !== -1 && indexB !== -1)
        return indexA - indexB
    if (indexA === -1 && indexB !== -1)
        return 1
    else if (indexA !== -1 && indexB === -1)
        return -1
    else if (indexA === -1 && indexB === -1)
        return 0
}