import axios from 'axios';
//Notistack
import ClasslessSnackbar from 'ClasslessSnackbar/ClasslessSnackbar';
//Redux
import store from 'app/store';
import { setUser } from 'app/slices/authenticationSlice/authenticationSlice';
//ServiceManager
import { URL, clearLocalStorage, updateLocalStorage } from 'ServiceManager/ServiceManager';

const showError = (message) => {
    ClasslessSnackbar.error(message)
}

const getRefreshTokenPromise = () => {    
    const rawUser = localStorage.getItem('user')

    console.log('rawUser', rawUser)
    if (!rawUser)  {
        return new Promise((resolve, reject) => {
            reject(new Error("Can't find refresh token for a null user"))
        })
    }

    const user = JSON.parse(rawUser)

    const requestBody = {
        RefreshToken: localStorage.getItem('refreshToken'),        
        Username: user.Username,
    }

    const newAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    return newAxios.post(URL.createRefreshToken(), requestBody)
        .then(res => {
            return {
                ...res.data.Data[0],
                Token: res.data.Token,
                RefreshToken: res.data.RefreshToken
            }
        })
}

const axiosConfig = {
    baseURL: process.env.REACT_APP_API_URL
};

const instance = axios.create(axiosConfig);

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';

    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error?.response?.status === 401) {
        return getRefreshTokenPromise()
            .then(refreshTokenResponse => {
                console.log('getRefreshTokenPromise then')
                const { token } = updateLocalStorage(refreshTokenResponse)

                error.config.headers['Authorization'] = 'bearer ' + token

                return axios.request(error.config)
                    .catch(err => {
                        showError(err)

                        return Promise.reject(err)
                    })
            })
            .catch((e2) => {
                console.log('getRefreshTokenPromise catch', e2)
                clearLocalStorage()

                store.dispatch(setUser(null))

                showError(error)
                return Promise.reject(error)
            })
    }
    else if (error && error?.response?.data?.message) {
        if (error?.response?.status !== 403)
            showError(error?.response?.data?.message)
    }

    return Promise.reject(error);
});

export default instance;