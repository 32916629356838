import { useEffect, useRef, useState } from 'react';

import AgGrid from "components/AgGrid/AgGrid";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//ColumnDef
import { getSkippedQuestionsColumnDefs } from './SkippedQuestionsColumnDefs/SkippedQuestionsColumnDefs';
import { useSnackbar } from 'notistack';

const GRID_REFRESH_INTERVAL_SECONDS = 60

const SkippedQuestionsPage = () => {
    const gridRef = useRef()
    const [rowData, setRowData] = useState(null)
    const { enqueueSnackbar } = useSnackbar()

    const updateGrid = () => {
        return ServiceManager.getSkippedQuestions()
            .then(res => {
                setRowData(res)
            })
    }

    useEffect(() => {
        updateGrid()

        const refreshInterval = setInterval(() => {
            updateGrid()
        }, GRID_REFRESH_INTERVAL_SECONDS * 1000)

        return () => {
            clearInterval(refreshInterval)
        }
    }, [])

    const onUnskipClick = (event, { startLoading, stopLoading, data, enque }) => {
        const { QuestionID } = data
        
        startLoading()
        ServiceManager.UnskipQuestion({ QuestionID })
            .then(res => {
                enqueueSnackbar('Questions is activated successfully!', { variant: 'success' })
            })
            .finally(() => {
                updateGrid()
                    .finally(() => {
                        stopLoading()
                    })
            })
    }

    return <div>
        <AgGrid ref={gridRef} showTotalRow={true} rowData={rowData} height={'83vh'} columnDefs={getSkippedQuestionsColumnDefs({ onUnskipClick })} />
    </div>
}

export default SkippedQuestionsPage;