import { useState, useEffect } from "react";
//MUI
import { CircularProgress } from "@mui/material";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Components
import SourceProfileButton from "./SourceProfileButton/SourceProfileButton";

const ProfileSwitchPanel = ({ onChange }) => {
    const [sourceProfiles, setSourceProfiles] = useState(null)
    const [selectedSourceProfile, setSelectedSourceProfile] = useState(null)

    useEffect(() => {
        ServiceManager.getSourceProfiles()
            .then(res => {
                setSourceProfiles(res.filter(item => item.IsActive))
            })
    }, [])

    const onSourceProfileClick = (event, { SourceProfileID }) => {
        setSelectedSourceProfile(SourceProfileID)
        if (onChange) {
            onChange({ SourceProfileID, SourceProfiles: sourceProfiles })
        }
    }

    return <div style={{ marginBottom: '5px' }}>
        {
            sourceProfiles ?
                <>
                    <SourceProfileButton SourceProfileID={null} isSelected={null === selectedSourceProfile} Name={'All'} DisplayName={'All'} onClick={onSourceProfileClick} />
                    {
                        sourceProfiles.map(item => {
                            return <SourceProfileButton key={item.SourceProfileID} isSelected={item.SourceProfileID === selectedSourceProfile} {...item} onClick={onSourceProfileClick} />
                        })
                    }
                </>
                :
                <CircularProgress />
        }
    </div>
}

export default ProfileSwitchPanel;