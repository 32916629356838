import { useEffect, useRef, useState } from 'react';

import AgGrid from "components/AgGrid/AgGrid";
//Services
import ServiceManager from 'ServiceManager/ServiceManager';
//Dialog
import AnsweredQuestionsRecordDialog from './AnsweredQuestionsRecordDialog/AnsweredQuestionsRecordDialog';
//ColumnDef
import { getAnsweredQuestionsColumnDefs } from './AnsweredQuestionsColumnDefs/AnsweredQuestionsColumnDefs';
//Components
import SourceProfileSwitchPanel from 'components/SourceProfileSwitchPanel/SourceProfileSwitchPanel';

const GRID_REFRESH_INTERVAL_SECONDS = 60

const AnsweredQuestionsPage = () => {
    const gridRef = useRef()
    const [rowData, setRowData] = useState(null)
    const [dialog, setDialog] = useState(null)
    const [sourceProfile, setSourceProfile] = useState({ sourceProfileID: null, sourceProfiles: [] })

    const updateGrid = (newSourceProfile) => {
        return ServiceManager.getAnsweredQuestions({ SourceProfileID: newSourceProfile ? newSourceProfile?.sourceProfileID : null })
            .then(res => {
                setRowData(res)
            })
    }

    useEffect(() => {
        updateGrid()
    }, [])

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            if (!dialog)
                updateGrid(sourceProfile)
        }, GRID_REFRESH_INTERVAL_SECONDS * 1000)

        return () => {
            clearInterval(refreshInterval)
        }
    }, [dialog, sourceProfile])

    const handleDialogClose = () => {
        setDialog(null)
    }

    const onShowAnswerClick = (event, { data }) => {
        setDialog(
            <AnsweredQuestionsRecordDialog
                data={data}
                defaultValue={{ AnswerHeadline: data.AnswerHeadline, AnswerBody: data.AnswerBody }}
                onClose={handleDialogClose}
            />
        )
    }

    const onSourceProfileChange = ({ SourceProfileID, SourceProfiles }) => {
        const newSourceProfile = {
            sourceProfileID: SourceProfileID,
            sourceProfiles: SourceProfiles,
        }

        setSourceProfile(newSourceProfile)
        updateGrid(newSourceProfile)
    }

    return <div>
        <SourceProfileSwitchPanel onChange={onSourceProfileChange} />
        <AgGrid ref={gridRef} showTotalRow={true} rowData={rowData} columnDefs={getAnsweredQuestionsColumnDefs({ answerButtonDisabled: !Boolean(sourceProfile.sourceProfileID), onShowAnswerClick })} height={'70vh'} />
        {dialog}
    </div>
}

export default AnsweredQuestionsPage;