
import { SourceProfileManagerColumns } from 'constants/Columns/Columns';

export const getUserSourceProfileColumnDefs = () => {

    return [
        {
            field: 'SelectionCheckbox',
            headerName: 'Select',
            width: 60,
            checkboxSelection: true,        
            headerCheckboxSelection: true,    
        },
        ...SourceProfileManagerColumns.map(item => {
            return {
                field: item.id,
                headerName: item.label,
                ...(item.gridProps || {}),
            }
        }),       
    ]
}