import React, { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//React router
import { useNavigate, useLocation } from "react-router-dom";
//Logo
import Logo from './Logo/Logo';

const Header = ({ pages, user, onLogoutClick }) => {
    const [menuAnchorElement, setMenuAnchorElement] = useState(null)
    const navigate = useNavigate()
    const location = useLocation()

    const onProfileIconClick = (event) => {
        setMenuAnchorElement(event.currentTarget)
    }

    const onHandleMenuClose = () => {
        setMenuAnchorElement(null)
    }

    const onLogoutClickMenuItemClick = () => {
        onHandleMenuClose()
        onLogoutClick()
    }

    const onMyAccountMenuItemClick = () => {
        onHandleMenuClose()
        navigate(`/MyAccount`)
    }

    return (
        <AppBar position="static" sx={{ background: 'white', boxShadow: 'none', padding: '14px' }}>
            <Toolbar disableGutters sx={{ marginLeft: '15px', marginRight: '15px' }}>
                <Logo />
                <Box sx={{ flexGrow: 1 }}>
                    {pages.filter(item => item.visible !== false).map((page) => (
                        <Button
                            key={page.id}
                            sx={{ my: 2, fontWeight: location.pathname === `/${page.id}` ? 'bold' : 'normal', color: 'black', display: 'inline-block', textTransform: 'none' }}
                            onClick={() => navigate(`/${page.id}`)}
                        >
                            {page.label}
                        </Button>
                    ))}
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open Profile">
                        <IconButton sx={{ p: 0 }} onClick={onProfileIconClick}>
                            <Avatar alt="Avatar name" src={user.Headshot} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Menu
                    id="ProfileMenu"
                    aria-labelledby="ProfileMenu"
                    anchorEl={menuAnchorElement}
                    open={Boolean(menuAnchorElement)}
                    onClose={onHandleMenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={onHandleMenuClose}>{`Welcome ${user.FirstName}`}</MenuItem>
                    <MenuItem onClick={onMyAccountMenuItemClick}>My Account</MenuItem>
                    <MenuItem onClick={onLogoutClickMenuItemClick}>Logout</MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    )
}

export default Header;