import { useEffect } from "react";

import { useParams } from "react-router-dom";

const RedirectHeadshotUrlPage = () => {
    const { id } = useParams();

    useEffect(() => {
        window.location.href = `https://source.tinymecha.com/api/getHeadshot/${id}`;
    }, [id]);

    return <h5>Redirecting...</h5>;
};

export default RedirectHeadshotUrlPage;